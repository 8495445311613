import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { ThemeContext } from '../contexts/ThemeContext';
import { IconButton, Switch } from '@mui/material';
import { Brightness4, Brightness7, Close, Menu } from '@mui/icons-material';
import { darkTheme } from '../styles/Theme'; // Add this import
import { useTheme } from '../contexts/ThemeContext';

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.navBackground};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  font-family: ${({ theme }) => theme.fonts.main};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0.5rem 20px;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 20px;
    justify-content: space-between;
  }
`;

const NavSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:nth-child(2) {
      display: none; // Hide the middle section (nav menu) on tablet and smaller screens
    }
  }
`;

const LeftSection = styled(NavSection)`
  justify-content: flex-start;
  width: 140px; // Fixed width for the logo section
  flex-shrink: 0; // Prevent shrinking

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
    padding-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: flex; // Ensure logo is visible on mobile
  }
`;

const Logo = styled.div`
  z-index: 1001;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%; // Take full width of LeftSection
  display: flex;
  align-items: center;
`;

const LogoText = styled.h1`
  font-weight: bold;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.green};
  white-space: nowrap; // Prevent text wrapping
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Add ellipsis for overflowing text
  margin: 0;
  padding: 0;
  width: 100%; // Take full width of Logo
`;

const ThemeToggle = styled.div`
  display: flex;
  align-items: center;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin: 0 0.75rem;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  transition: color 0.3s ease;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.main};

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const StyledSwitch = styled(Switch)`
  && {
    .MuiSwitch-track {
      background-color: ${({ theme }) => theme.colors.primary}; // Use primary color for both modes
    }
    .MuiSwitch-thumb {
      background-color: ${({ theme }) => theme.colors.background.includes('#f0f0f0') ? '#fff' : '#333'}; // Light thumb in dark mode, dark thumb in light mode
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const MobileMenuButton = styled(IconButton)`
  margin-left: 1rem;
  z-index: 1002;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.burgerMenu};
  }
`;

const MobileMenu = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.navBackground};
  z-index: 999;
  overflow-y: auto; // Add scroll if content overflows
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 40px; // Add some bottom padding
    background-color: ${({ theme }) => 
      theme.isDarkMode 
        ? `rgba(18, 18, 18, 0.95)`
        : 'rgba(255, 255, 255, 0.95)'
    };
  }
`;

const RightSection = styled(NavSection)`
  justify-content: flex-end;
`;

const MobileNavList = styled(NavList)`
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MobileNavItem = styled(NavItem)`
  margin: 1rem 0;
`;

const MobileNavLink = styled(NavLink)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.isDarkMode ? theme.colors.green : theme.colors.text};
`;

const Navigation = () => {
  const { isDarkMode, toggleTheme, theme } = useTheme();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isTabletOrSmaller, setIsTabletOrSmaller] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsTabletOrSmaller(window.innerWidth <= parseInt(theme.breakpoints.tablet));
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, [theme.breakpoints.tablet]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    closeMobileMenu();
  };

  const handleNavLinkClick = (to, offset) => {
    closeMobileMenu();
  };

  return (
    <NavContainer>
      <NavContent>
        <LeftSection>
          <Logo 
            onClick={scrollToTop} 
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                scrollToTop();
              }
            }}
            role="button"
            tabIndex={0}
            aria-label="Scroll to top"
          >
            <LogoText>Doc Miner</LogoText>
          </Logo>
        </LeftSection>
        <NavSection>
          <Nav>
            <NavList>
              <NavItem>
                <NavLink to="top" smooth={true} duration={500} onClick={scrollToTop}>
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="tracking" smooth={true} duration={500} offset={-70}>
                  Tracking
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="industries" smooth={true} duration={500} offset={-70}>
                  Industries
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="security" smooth={true} duration={500} offset={-70}>
                  Security
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="user-experience" smooth={true} duration={500} offset={-70}>
                  UX
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="technology" smooth={true} duration={500} offset={-70}>
                  Tech
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="contact" smooth={true} duration={500} offset={-70}>
                  Contact
                </NavLink>
              </NavItem>
            </NavList>
          </Nav>
        </NavSection>
        <RightSection>
          <ThemeToggle>
            <StyledIconButton onClick={toggleTheme} size="small">
              {isDarkMode ? <Brightness7 /> : <Brightness4 />}
            </StyledIconButton>
            <StyledSwitch checked={isDarkMode} onChange={toggleTheme} size="small" />
          </ThemeToggle>
          <MobileMenuButton 
            onClick={toggleMobileMenu} 
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
            disabled={!isTabletOrSmaller}
          >
            {isMobileMenuOpen ? <Close /> : <Menu />}
          </MobileMenuButton>
        </RightSection>
      </NavContent>
      <MobileMenu isOpen={isMobileMenuOpen}>
        <MobileNavList>
          <MobileNavItem>
            <MobileNavLink to="top" smooth={true} duration={500} onClick={scrollToTop}>
              Home
            </MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="tracking" smooth={true} duration={500} offset={-70} onClick={() => handleNavLinkClick("tracking", -70)}>
              Tracking
            </MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="industries" smooth={true} duration={500} offset={-70} onClick={() => handleNavLinkClick("industries", -70)}>
              Industries
            </MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="security" smooth={true} duration={500} offset={-70} onClick={() => handleNavLinkClick("security", -70)}>
              Security
            </MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="user-experience" smooth={true} duration={500} offset={-70} onClick={() => handleNavLinkClick("user-experience", -70)}>
              UX
            </MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="technology" smooth={true} duration={500} offset={-70} onClick={() => handleNavLinkClick("technology", -70)}>
              Tech
            </MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="contact" smooth={true} duration={500} offset={-70} onClick={() => handleNavLinkClick("contact", -70)}>
              Contact
            </MobileNavLink>
          </MobileNavItem>
        </MobileNavList>
      </MobileMenu>
    </NavContainer>
  );
};

export default Navigation;