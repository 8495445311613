import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import styled from 'styled-components';

const VisualizationWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const GraphVisualization = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    if (!mountRef.current) return;

    let scene, camera, renderer, controls, graphGroup;
    let frameId = null;

    const init = () => {
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;

      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000);
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(width, height);
      mountRef.current.appendChild(renderer.domElement);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.minDistance = 100;
      controls.maxDistance = 500;
      controls.maxPolarAngle = Math.PI;
      controls.enableZoom = false;  // Disable zoom here

      graphGroup = new THREE.Group();
      scene.add(graphGroup);

      camera.position.z = 160;

      createNodes();
      animate();
      handleResize();

      window.addEventListener('resize', handleResize);
    };

    const createNodes = () => {
      const nodes = [];
      const nodeCount = 50;
      const centralNodeCount = 5;
      const outerNodeSize = 1.2;
      const centralNodeSize = outerNodeSize * 3;

      for (let i = 0; i < nodeCount; i++) {
        const isCentralNode = i < centralNodeCount;
        const nodeSize = isCentralNode ? centralNodeSize : outerNodeSize;
        const nodeGeometry = new THREE.SphereGeometry(nodeSize, 32, 32);
        const nodeMaterial = new THREE.MeshBasicMaterial({ color: isCentralNode ? 0x00ff00 : 0x00ff00 });
        
        const node = new THREE.Mesh(nodeGeometry, nodeMaterial);

        if (isCentralNode) {
          const angle = (i / centralNodeCount) * Math.PI * 2;
          node.position.set(
            Math.cos(angle) * 30,
            Math.sin(angle) * 30,
            0
          );
        } else {
          const phi = Math.acos(-1 + (2 * (i - centralNodeCount)) / (nodeCount - centralNodeCount));
          const theta = Math.sqrt((nodeCount - centralNodeCount) * Math.PI) * phi;
          node.position.set(
            Math.cos(theta) * Math.sin(phi) * 75,
            Math.sin(theta) * Math.sin(phi) * 75,
            Math.cos(phi) * 75
          );
        }

        graphGroup.add(node);
        nodes.push({ mesh: node, connections: 0 });
      }

      const edgeMaterial = new THREE.LineBasicMaterial({ color: 0x00bfff, opacity: 0.4, transparent: true });

      for (let i = 0; i < centralNodeCount; i++) {
        for (let j = centralNodeCount; j < nodes.length; j++) {
          if (nodes[j].connections < 2 && Math.random() > 0.7) {
            const geometry = new THREE.BufferGeometry().setFromPoints([
              nodes[i].mesh.position,
              nodes[j].mesh.position
            ]);
            const edge = new THREE.Line(geometry, edgeMaterial);
            graphGroup.add(edge);
            nodes[i].connections++;
            nodes[j].connections++;
          }
        }
      }
    };

    const animate = () => {
      frameId = requestAnimationFrame(animate);
      graphGroup.rotation.y += 0.00110;
      controls.update();
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      if (!mountRef.current) return;
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    init();

    return () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }
      if (controls) {
        controls.dispose();
      }
      if (renderer) {
        renderer.dispose();
      }
      if (mountRef.current && renderer && renderer.domElement) {
        mountRef.current.removeChild(renderer.domElement);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <VisualizationWrapper ref={mountRef} />;
};

export default GraphVisualization;