import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper, Title as BaseTitle, Paragraph } from './CommonStyles';
import Globe from './Globe';

const AnimatedSection = styled(BaseSectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;
  width: 100%;
  max-width: none;
  padding: 3rem 0;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: 2rem;
  flex-direction: row-reverse;

  @media (max-width: 1023px) {
    flex-direction: column-reverse; // Stack vertically on tablet, Globe on top
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse; // Maintain vertical stack on mobile
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 50%;
  margin-right: auto;
  padding: 0 2rem;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;
    text-align: left; // Changed from center to left
    padding: 0;
    margin-top: 2rem; // Add space between Globe and content
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start; // Changed from space-between to flex-start
`;

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: auto; // Changed from fixed height to auto
  min-height: 270px; // Increased to accommodate 9 lines (30px per line)
  margin: 0.375rem 0;
`;

const IndustryContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${props => props.offset}%);
  display: flex;
  flex-direction: column;
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center; // Center the arrows horizontally
  align-items: center;
  margin-top: 1rem; // Add some space above the arrows
`;

const StyledTitle = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  letter-spacing: normal;
  margin-bottom: 1rem;
`;

const IndustryTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.green}; // This will use the correct color from the theme
  font-weight: normal;
  letter-spacing: normal;
  font-family: ${({ theme }) => theme.fonts.main};
  text-align: left; // Added to ensure left alignment
`;

const IndustryDescription = styled(Paragraph)`
  margin-bottom: 0.5rem; // Space between paragraphs
  font-size: 0.9rem; // Slightly smaller font size to fit more text
  line-height: 1.5; // Adjusted for readability
`;

const ArrowButton = styled.button`
  background: transparent;
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.disabled : theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme, disabled }) => disabled ? theme.colors.disabled : theme.colors.primary};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2rem 0 0;
  transition: all 0.3s ease;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};

  &:hover {
    background-color: ${({ theme, disabled }) => disabled ? 'transparent' : theme.colors.primary};
    color: ${({ theme, disabled }) => disabled ? theme.colors.disabled : theme.colors.background};
    box-shadow: ${({ disabled }) => disabled ? 'none' : `0 0 10px ${({ theme }) => theme.colors.primary}`};
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ArrowIcon = ({ direction }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: direction === 'left' ? 'rotate(180deg)' : 'none' }}
  >
    <path
      d="M9 18L15 12L9 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const StyledTransparentContentWrapper = styled(TransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const GlobeContainer = styled.div`
  width: 50%;
  max-width: 500px;
  height: auto;
  aspect-ratio: 1;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const IndustriesSection = React.forwardRef(({ animationDelay = 0.3 }, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState(0);
  const containerRef = useRef(null);
  const sectionRef = useRef(null);
  const [globeSize, setGlobeSize] = useState({ width: 300, height: 300 });
  const globeContainerRef = useRef(null);
  const [colorSchemeIndex, setColorSchemeIndex] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (globeContainerRef.current) {
        const { width, height } = globeContainerRef.current.getBoundingClientRect();
        setGlobeSize({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const industryDescriptions = {
    energy: [
      'Optimize document management in the energy sector, from contracts to regulatory compliance.',
      'Streamline processes for handling complex documentation related to exploration, production, and distribution.',
      'Enhance collaboration between field operations and corporate offices through centralized document access.'
    ],
    logistics: [
      'Efficiently handle shipping documents, invoices, and customs paperwork in the logistics industry.',
      'Improve tracking and management of transportation documents across various modes of shipping.',
      'Reduce errors and delays in customs clearance through automated document processing.'
    ],
    finance: [
      'Ensure compliance and streamline financial document processing for banks and financial institutions.',
      'Enhance security and accessibility of sensitive financial records and customer information.',
      'Accelerate loan processing and approval workflows through intelligent document routing.'
    ],
    lawFirms: [
      'Organize and analyze vast amounts of legal documents for improved case management.',
      'Facilitate secure collaboration among legal teams with controlled access to case files.',
      'Streamline contract review processes using AI-powered document analysis.'
    ],
    courthouses: [
      'Manage legal documents and case files with enhanced security and accessibility.',
      'Improve efficiency in court proceedings through digital document management and retrieval.',
      'Ensure compliance with record retention policies and facilitate public record requests.'
    ],
    government: [
      'Streamline document processes for improved efficiency in government operations and public services.',
      'Enhance transparency and accountability in document handling across various departments.',
      'Facilitate inter-agency collaboration through secure document sharing and version control.'
    ]
  };

  const industries = [
    { name: 'Energy', description: industryDescriptions.energy, colorSchemeIndex: 0 }, // Using index 4 for lime green
    { name: 'Logistics', description: industryDescriptions.logistics, colorSchemeIndex: 5 },
    { name: 'Finance', description: industryDescriptions.finance, colorSchemeIndex: 3 },
    { name: 'Law Firms', description: industryDescriptions.lawFirms, colorSchemeIndex: 1 },
    { name: 'Courthouses', description: industryDescriptions.courthouses, colorSchemeIndex: 2 },
    { name: 'Government', description: industryDescriptions.government, colorSchemeIndex: 6 },
  ];

  const isFirstIndustry = currentIndex === 0;
  const isLastIndustry = currentIndex === industries.length - 1;

  const changeIndustry = useCallback((newDirection) => {
    if (isAnimating) return;
    if ((newDirection === -1 && isFirstIndustry) || (newDirection === 1 && isLastIndustry)) return;
    
    setIsAnimating(true);
    setDirection(newDirection);
    
    const nextIndex = currentIndex + newDirection;
    
    setTimeout(() => {
      setCurrentIndex(nextIndex);
      setColorSchemeIndex(industries[nextIndex].colorSchemeIndex);
      setIsAnimating(false);
      setDirection(0);
    }, 300);
  }, [currentIndex, isAnimating, isFirstIndustry, isLastIndustry, industries]);

  const prevIndustry = useCallback(() => changeIndustry(-1), [changeIndustry]);
  const nextIndustry = useCallback(() => changeIndustry(1), [changeIndustry]);

  const getOffset = (index) => {
    switch (direction) {
      case 0:
        return index * 100;
      case 1: // Right arrow click
        return -100 + index * 100;
      case -1: // Left arrow click
        return 100 + index * 100;
    }
  };

  const getIndex = (offset) => {
    return (currentIndex + offset) % industries.length;
  };

  return (
    <AnimatedSection 
      id="industries" 
      ref={(node) => {
        sectionRef.current = node;
        if (typeof ref === 'function') ref(node);
        else if (ref) ref.current = node;
      }}
      className={isVisible ? 'visible' : ''} 
      delay={animationDelay}
    >
      <StyledTransparentContentWrapper>
        <FlexWrapper>
          <GlobeContainer ref={globeContainerRef}>
            <Globe 
              width={globeSize.width} 
              height={globeSize.height} 
              colorSchemeIndex={colorSchemeIndex}
            />
          </GlobeContainer>
            <ContentWrapper>
            <ContentInnerWrapper>
              <StyledTitle>Industries</StyledTitle>
              <CarouselContainer ref={containerRef}>
                {[0, 1].map((offset) => {
                  const index = getIndex(offset);
                  return (
                    <IndustryContent key={index} offset={getOffset(offset)}>
                      <IndustryTitle>{industries[index].name}</IndustryTitle>
                      {industries[index].description.map((paragraph, i) => (
                        <IndustryDescription key={i}>{paragraph}</IndustryDescription>
                      ))}
                    </IndustryContent>
                  );
                })}
              </CarouselContainer>
              <ArrowContainer>
                <ArrowButton 
                  onClick={prevIndustry} 
                  disabled={isFirstIndustry || isAnimating}
                >
                  <ArrowIcon direction="left">➜</ArrowIcon>
                </ArrowButton>
                <ArrowButton 
                  onClick={nextIndustry} 
                  disabled={isLastIndustry || isAnimating}
                >
                  <ArrowIcon direction="right">➜</ArrowIcon>
                </ArrowButton>
              </ArrowContainer>
            </ContentInnerWrapper>
          </ContentWrapper>
        </FlexWrapper>
      </StyledTransparentContentWrapper>
    </AnimatedSection>
  );
});

export default IndustriesSection;