import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper as BaseTransparentContentWrapper, Paragraph } from './CommonStyles';
import GraphVisualization from './GraphVisualization';
import AnimatedSection from './AnimatedSection';

const AnimatedSectionStyled = styled(BaseSectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;
  width: 100%;
  max-width: none;
  padding: 4rem 0;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const TransparentContentWrapper = styled(BaseTransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const TextContent = styled.div`
  flex: 1;
  max-width: 60%; // Adjust this value to your preference
  
  @media (max-width: 1024px) {
    max-width: 70%; // Slightly wider on smaller screens
  }

  @media (max-width: 768px) {
    max-width: 100%; // Full width on mobile
  }
`;

const ImagePlaceholder = styled.div`
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }

  > div {
    width: 100%;
    height: 100%;
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -400% 50%;
  }
`;

const AnimatedTitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  background: linear-gradient(
    90deg,
    #4a9eff,
    #00bfff,
    #32CD32,
    #a020f0,
    #4a9eff
  );
  background-size: 400% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  animation: ${gradientAnimation} 16s linear infinite; // Changed from 8s to 16s
  display: inline-block;
`;

const GreenText = styled.span`
  color: #00ff00; // Lime green color
`;

const SeeEverythingText = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 700; // This makes it bold
  // ... other styles
`;

const HomeSection = ({ animationDelay = 0.1 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <AnimatedSectionStyled 
      id="home" 
      ref={sectionRef} 
      className={isVisible ? 'visible' : ''} 
      delay={animationDelay}
    >
      <TransparentContentWrapper>
        <FlexWrapper>
          <TextContent>
            <AnimatedTitle>See Everything</AnimatedTitle>
            <Paragraph>
              <GreenText>Doc Miner</GreenText> unlocks the power of your documents. Our advanced AI-driven platform transforms the way you process, analyze, and extract insights from your documents.
            </Paragraph>
            <Paragraph>
              Whether you're dealing with contracts, reports, or any other type of document, Doc Miner provides the tools you need to make informed decisions faster.
            </Paragraph>
          </TextContent>
          <ImagePlaceholder>
            <GraphVisualization /> {/* Replace the old animation with GraphVisualization */}
          </ImagePlaceholder>
        </FlexWrapper>
      </TransparentContentWrapper>
    </AnimatedSectionStyled>
  );
};

export default HomeSection;
