import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper as BaseTransparentContentWrapper, Title as BaseTitle } from './CommonStyles';
import { useNavigate } from 'react-router-dom';  // Add this line
import { animateScroll as scroll } from 'react-scroll';  // Add this import

const AnimatedSection = styled(BaseSectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;
  width: 100%;
  max-width: none;
  padding: 4rem 0;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const TransparentContentWrapper = styled(BaseTransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
`;

const Input = styled.input`
  padding: 0.5rem;
  background-color: #333;
  border: none;
  border-radius: 4px;
  color: white;
  width: 100%;

  &::placeholder {
    color: #00ff00;
  }
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  background-color: #333;
  border: none;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
  color: white;
  width: 100%;

  &::placeholder {
    color: #00ff00;
  }
`;

const slideAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #333;
  color: #00ff00;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  align-self: center;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #00bfff;
    color: #000000;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: none;
    z-index: 1;
  }

  &.loading::before {
    left: 100%;
    animation: ${slideAnimation} 1s infinite;
  }
`;

const SideColumn = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
`;

const Title = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  color: white;
  margin: 0;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => 
    theme.isDarkMode 
      ? `rgba(18, 18, 18, 0.95)`
      : 'rgba(255, 255, 255, 0.95)'
  };
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  color: ${({ theme }) => theme.colors.limeGreen};
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const CloseButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.limeGreen};
  border: 2px solid ${({ theme }) => theme.colors.limeGreen};
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.limeGreen};
    color: ${({ theme }) => theme.isDarkMode ? '#000000' : '#ffffff'};
  }
`;

const ContactSection = ({ animationDelay = 0.5 }) => {
  const navigate = useNavigate();  // Add this line
  const [isVisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const sectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('http://localhost:3001/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setShowModal(true);
        setFormData({ name: '', email: '', message: '' });
      } else {
        console.error('Server response:', data);
        alert(`Failed to send message: ${data.message}`);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    scroll.scrollToTop({
      duration: 500,
      smooth: true
    });
  };

  return (
    <AnimatedSection 
      id="contact" 
      ref={sectionRef} 
      className={isVisible ? 'visible' : ''} 
      delay={animationDelay}
    >
      <TransparentContentWrapper>
        <FlexWrapper>
          <SideColumn>
            <Title>Contact Us</Title>
            <Subtitle>The Woodlands, TX</Subtitle>
          </SideColumn>
          <ContactForm onSubmit={handleSubmit}>
            <Input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
            <Input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
            <TextArea name="message" placeholder="Message" value={formData.message} onChange={handleInputChange} required />
            <SubmitButton 
              type="submit" 
              disabled={isLoading}
              className={isLoading ? 'loading' : ''}
            >
              {isLoading ? 'Sending...' : 'Send'}
            </SubmitButton>
          </ContactForm>
        </FlexWrapper>
      </TransparentContentWrapper>
      {showModal && (
        <Modal>
          <ModalContent>
            <p>Email sent successfully!</p>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </Modal>
      )}
    </AnimatedSection>
  );
};

export default ContactSection;
