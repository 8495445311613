import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrambleContainer = styled.div`
  font-family: monospace;
  font-size: 24px;
  color: #00ff00; // Lime matrix terminal green
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const TextBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
`;

const ScrambleLine = styled.div`
  height: 1.2em;
  line-height: 1.2em;
  text-align: center;
`;

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';

const SecurityScrambler = () => {
  const [displayText, setDisplayText] = useState([]);
  const [finalMessageVisible, setFinalMessageVisible] = useState(false);
  const sentences = [
    "Contracts",
    "Invoices",
    "Judgements",
    "Titles",
    "Financials",
    "Your data is secure."
  ];

  useEffect(() => {
    let sentenceIndex = 0;
    let charIndex = 0;

    const scrambleText = () => {
      if (sentenceIndex < sentences.length - 1) {
        const currentSentence = sentences[sentenceIndex];
        const scrambledPart = Array(currentSentence.length - charIndex)
          .fill()
          .map(() => characters[Math.floor(Math.random() * characters.length)])
          .join('');
        const newText = currentSentence.slice(0, charIndex) + scrambledPart;
        
        setDisplayText(prevText => {
          const newDisplayText = [...prevText];
          newDisplayText[sentenceIndex] = newText;
          return newDisplayText;
        });

        charIndex++;

        if (charIndex > currentSentence.length) {
          charIndex = 0;
          sentenceIndex++;
          setTimeout(scrambleText, 1000);
        } else {
          setTimeout(scrambleText, 50);
        }
      } else if (sentenceIndex === sentences.length - 1) {
        setTimeout(() => {
          setFinalMessageVisible(true);
          scrambleFinalMessage();
        }, 1000);
      }
    };

    const scrambleFinalMessage = () => {
      let charIndex = 0;
      const finalMessage = sentences[sentences.length - 1];
      
      const scrambleFinal = () => {
        const scrambledPart = Array(finalMessage.length - charIndex)
          .fill()
          .map(() => characters[Math.floor(Math.random() * characters.length)])
          .join('');
        const newText = finalMessage.slice(0, charIndex) + scrambledPart;
        
        setDisplayText(prev => {
          const newDisplayText = [...prev];
          newDisplayText[sentences.length - 1] = newText;
          return newDisplayText;
        });

        charIndex++;

        if (charIndex <= finalMessage.length) {
          setTimeout(scrambleFinal, 50);
        }
      };

      scrambleFinal();
    };

    setDisplayText(sentences.map(() => ''));
    scrambleText();

    return () => clearTimeout();
  }, []);

  return (
    <ScrambleContainer>
      <TextBlock visible={!finalMessageVisible}>
        {sentences.slice(0, -1).map((_, index) => (
          <ScrambleLine key={index}>
            {displayText[index] || '\u00A0'}
          </ScrambleLine>
        ))}
      </TextBlock>
      <TextBlock visible={finalMessageVisible}>
        <ScrambleLine>
          {displayText[sentences.length - 1] || '\u00A0'}
        </ScrambleLine>
      </TextBlock>
    </ScrambleContainer>
  );
};

export default SecurityScrambler;