import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: 2rem 0;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Copyright = styled.p`
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 300; // Regular weight
  font-size: 0.9rem; // Slightly smaller font size
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <FooterContent>
        <Copyright>&copy; {currentYear} Doc-Miner. All rights reserved.</Copyright>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;