import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { interpolateRainbow } from 'd3-scale-chromatic';

const AnimateNN = ({ width, height, onReshuffleColors }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const [data, setData] = useState(null);
  const [isGrowing, setIsGrowing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [colorScale, setColorScale] = useState(() => d3.scaleSequential(interpolateRainbow));

  const generateRandomBranch = (depth = 0, maxDepth = 4, count = { value: 1 }, minBranches = 9) => {
    if (depth >= maxDepth) return null;
    const currentCount = count.value++;
    
    let hasChildren = depth < 3 || (Math.random() < 0.7 && minBranches > 1);
    let numChildren = 0;

    if (hasChildren) {
      if (depth === 0) {
        numChildren = Math.floor(Math.random() * 3) + 3; // 3 to 5 children for root
      } else if (depth === 1) {
        numChildren = Math.floor(Math.random() * 2) + 2; // 2 to 3 children for second level
      } else if (depth === 2) {
        numChildren = Math.floor(Math.random() * 2) + 1; // 1 to 2 children for third level
      } else {
        numChildren = Math.floor(Math.random() * 3) + 1; // 1 to 3 children for others
      }
    }

    return {
      name: currentCount.toString(),
      children: Array.from({ length: numChildren }, (_, i) => 
        generateRandomBranch(depth + 1, maxDepth, count, Math.max(1, Math.floor((minBranches - 1) / numChildren)))
      ).filter(Boolean)
    };
  };

  const regenerateNN = () => {
    setIsGrowing(true);
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    
    const generateTreeWithinBranchRange = () => {
      let newData;
      let branchCount;
      do {
        newData = generateRandomBranch(0, 4, { value: 1 }, 9);
        branchCount = countBranches(newData);
      } while (branchCount < 9 || branchCount > 30 || !hasFourGenerations(newData));
      return newData;
    };

    setTimeout(() => setData(generateTreeWithinBranchRange()), 100);
  };

  const countBranches = (node) => {
    if (!node) return 0;
    if (!node.children || node.children.length === 0) return 1;
    return 1 + node.children.reduce((sum, child) => sum + countBranches(child), 0);
  };

  const hasFourGenerations = (node) => {
    if (!node || !node.children) return false;
    return node.children.some(child => 
      child.children && child.children.some(grandchild => 
        grandchild.children && grandchild.children.some(greatGrandchild => 
          greatGrandchild.children
        )
      )
    );
  };

  const reshuffleColors = useCallback(() => {
    if (svgRef.current && data) {
      const svg = d3.select(svgRef.current);
      const root = d3.hierarchy(data);
      const newColorScale = d3.scaleSequential(interpolateRainbow)
        .domain([0, root.descendants().length]);
      
      svg.selectAll(".node circle")
        .transition()
        .duration(1000)
        .attr("fill", d => newColorScale(d.id));

      svg.selectAll(".link")
        .transition()
        .duration(1000)
        .attr("stroke", d => newColorScale(d.source.id));

      setColorScale(() => newColorScale);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 1.0 } // 1.0 means the entire element must be visible
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible) {
      regenerateNN();
    }
  }, [isVisible]);

  useEffect(() => {
    if (svgRef.current && data) {
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove();

      const margin = { top: 20, right: 20, bottom: 20, left: 20 };
      const innerWidth = width - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;

      const root = d3.hierarchy(data);
      root.descendants().forEach((d, i) => d.id = i); // Assign unique IDs
      const treeLayout = d3.tree().size([innerHeight, innerWidth]);
      treeLayout(root);

      root.x = innerHeight / 2;
      root.y = 0;

      const g = svg.append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const link = g.selectAll(".link")
        .data(root.links())
        .enter().append("path")
        .attr("class", "link")
        .attr("fill", "none")
        .attr("stroke", "#00BFFF")
        .attr("stroke-opacity", 0)
        .attr("stroke-width", 2)
        .attr("d", d3.linkHorizontal()
          .x(d => d.y)
          .y(d => d.x));

      const linkHitArea = g.selectAll(".link-hit-area")
        .data(root.links())
        .enter().append("path")
        .attr("class", "link-hit-area")
        .attr("fill", "none")
        .attr("stroke", "transparent")
        .attr("stroke-width", 10)
        .attr("d", d3.linkHorizontal()
          .x(d => d.y)
          .y(d => d.x));

      const node = g.selectAll(".node")
        .data(root.descendants())
        .enter().append("g")
        .attr("class", "node")
        .attr("transform", d => `translate(${d.parent ? d.parent.y : d.y},${d.parent ? d.parent.x : d.x})`)
        .style("opacity", 0);

      const rootRadius = 6;
      const getNodeRadius = d => {
        if (d.depth === 0) return rootRadius;
        if (d.depth === 1) return rootRadius * 0.8;
        if (d.depth === 2) return rootRadius * 0.6;
        return rootRadius * 0.4;
      };

      node.append("circle")
        .attr("r", d => getNodeRadius(d))
        .attr("fill", d => colorScale(d.id));

      node.append("circle")
        .attr("r", d => getNodeRadius(d) * 2)
        .attr("fill", "transparent");

      node.append("text")
        .attr("dy", "-0.5em")
        .attr("x", "-0.5em")
        .style("text-anchor", "end")
        .text(d => d.data.name)
        .style("fill", "white")
        .style("font-size", "12px")
        .style("fill-opacity", 0);

      function handleMouseOver(d) {
        const nodeElement = node.filter(n => n === d);
        nodeElement.select("circle")
          .transition()
          .duration(300)
          .attr("fill", "#FFD700");
        
        nodeElement.select("text")
          .transition()
          .duration(300)
          .style("fill", "#FFD700");

        link.filter(l => l.source === d || l.target === d)
          .transition()
          .duration(300)
          .attr("stroke", "#FFD700");
      }

      function handleMouseOut(d) {
        const nodeElement = node.filter(n => n === d);
        nodeElement.select("circle")
          .transition()
          .duration(300)
          .attr("fill", "#32CD32");
        
        nodeElement.select("text")
          .transition()
          .duration(300)
          .style("fill", "white");

        link.filter(l => l.source === d || l.target === d)
          .transition()
          .duration(300)
          .attr("stroke", l => colorScale(l.source.id));
      }

      function enableHoverEffects() {
        node.on("mouseenter", (event, d) => handleMouseOver(d))
            .on("mouseleave", (event, d) => handleMouseOut(d));

        linkHitArea.on("mouseenter", (event, d) => {
          handleMouseOver(d.source);
          handleMouseOver(d.target);
        })
        .on("mouseleave", (event, d) => {
          handleMouseOut(d.source);
          handleMouseOut(d.target);
        });
      }

      // Reduce duration and delays by half
      const duration = 1000; // Changed from 2000
      const rootDelay = 1; // Changed from 5 to 1
      const normalDelay = 100; // Changed from 250 to 100

      function growNN(nodeSelection, depth = 0) {
        const currentDelay = depth === 0 ? 0 : depth === 1 ? rootDelay : normalDelay;

        link.filter(l => nodeSelection.data().includes(l.target))
          .transition()
          .duration(duration)
          .delay(depth * currentDelay)
          .attrTween("d", function(d) {
            const source = {x: d.source.x, y: d.source.y};
            const target = {x: d.target.x, y: d.target.y};
            const i = d3.interpolate(source, target);
            return t => d3.linkHorizontal()
              .x(d => d.y)
              .y(d => d.x)
              ({source, target: i(t)});
          })
          .style("stroke-opacity", 1)
          .attr("stroke", d => colorScale(d.source.id));

        nodeSelection
          .transition()
          .duration(duration)
          .delay(depth * currentDelay)
          .attrTween("transform", function(d) {
            const startPos = d.parent ? {x: d.parent.x, y: d.parent.y} : {x: d.x, y: d.y};
            const endPos = {x: d.x, y: d.y};
            const i = d3.interpolate(startPos, endPos);
            return t => `translate(${i(t).y},${i(t).x})`;
          })
          .on("start", function() {
            d3.select(this).style("opacity", 1);
            d3.select(this).select("text")
              .style("fill-opacity", 0.5);
          })
          .on("end", function(d) {
            d3.select(this).select("text")
              .transition()
              .duration(duration / 2)
              .style("fill-opacity", 1);

            if (d.children) {
              growNN(node.filter(n => d.children.includes(n)), depth + 1);
            } else if (depth === root.height) {
              setIsGrowing(false);
              enableHoverEffects();
            }
          });
      }

      growNN(node.filter(d => !d.parent), 0);

      svg.on("click", (event) => {
        if (event.target === svg.node()) {
          reshuffleColors();
        } else {
          regenerateNN();
        }
      });
    }
  }, [data, width, height, reshuffleColors]);

  useEffect(() => {
    if (onReshuffleColors) {
      onReshuffleColors(reshuffleColors);
    }
  }, [reshuffleColors, onReshuffleColors]);

  return (
    <div ref={containerRef}>
      <svg ref={svgRef} width={width} height={height}></svg>
    </div>
  );
};

export default AnimateNN;