const breakpoints = {
  mobile: '576px',
  tablet: '768px',
  desktop: '1024px',
};

const baseTheme = {
  fonts: {
    main: "'Inter', sans-serif",
  },
  breakpoints: {
    mobile: '576px',
    tablet: '768px',
    desktop: '1024px',
  },
  colors: {
    limeGreen: '#32CD32',
  },
};

export const lightTheme = {
  ...baseTheme,
  isDarkMode: false,
  colors: {
    ...baseTheme.colors,
    background: '#1B4977', // Flat color for light mode background
    text: '#FFFFFF', // Keeping white text for contrast
    primary: '#FFD700', // Keeping gold color
    accent: '#4CAF50',
    navBackground: 'rgba(27, 73, 119, 0.9)', // Keeping the light blue header
    borderColor: '#FFFFFF',
    darkGreen: '#004d00',
    green: '#32CD32',
    sectionTitle: '#FFFFFF',
    burgerMenu: '#FFFFFF',
    nodeColor: '#FFD700',
    edgeColor: '#A8C1D9',
  },
};

export const darkTheme = {
  ...baseTheme,
  isDarkMode: true,
  colors: {
    ...baseTheme.colors,
    background: '#121212', // Dark background
    text: '#f1f1f1',
    primary: '#4a9eff',
    accent: '#45a049',
    navBackground: 'rgba(30, 30, 30, 0.9)',
    borderColor: '#00a000',
    darkGreen: '#004d00',
    green: '#32CD32',
    sectionTitle: '#f1f1f1',
    burgerMenu: '#32CD32',
    nodeColor: '#00ff00',
    edgeColor: '#00bfff',
  },
};