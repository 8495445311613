import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper as BaseTransparentContentWrapper, Title as BaseTitle, Paragraph } from './CommonStyles';
import AnimateMindMap from './AnimateMindMap';

// Override the SectionWrapper to ensure full width
const SectionWrapper = styled(BaseSectionWrapper)`
  width: 100%;
  max-width: none;
  padding: 4rem 0;
`;

// Override the TransparentContentWrapper to match other sections
const TransparentContentWrapper = styled(BaseTransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
`;

const ImagePlaceholder = styled.div`
  width: 300px;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.borderColor};
  border-radius: 10px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-x: auto; // Allow horizontal scrolling if needed
  -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS

  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for WebKit browsers
  }
  -ms-overflow-style: none; // Hide scrollbar for IE and Edge
  scrollbar-width: none; // Hide scrollbar for Firefox
`;

const Tab = styled.button`
  background: ${({ theme, active }) => active ? theme.colors.background : 'transparent'};
  border: 1px solid ${({ active, theme }) => 
    active 
      ? theme.colors.borderColor 
      : theme.colors.background.includes('#f0f0f0') ? theme.colors.lightGreen : '#008000'
  };
  border-bottom: ${({ active, theme }) => active ? `1px solid ${theme.colors.background}` : `1px solid ${theme.colors.lightGreen}`};
  border-radius: 10px 10px 0 0;
  color: ${({ active, theme }) => 
    active 
      ? theme.colors.background.includes('#f0f0f0') ? '#0000FF' : '#00BFFF'
      : theme.colors.text
  };
  cursor: pointer;
  font-size: 0.9rem; // Slightly reduce font size
  font-weight: normal;
  padding: 0.5rem 1rem; // Reduce padding
  margin-right: -1px;
  transition: all 0.3s ease;
  position: relative;
  z-index: ${({ active }) => active ? 2 : 1};
  white-space: nowrap; // Prevent text wrapping

  ${({ active }) => active && `
    margin-bottom: -1px;
  `}

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  // Remove media query for mobile
`;

const TabContentContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; // Prevent content from overflowing
`;

const TabContent = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0 10px 10px 10px;
  padding: 1rem; // Slightly reduce padding
  display: ${({ active }) => active ? 'flex' : 'none'};
  flex-direction: column;
  flex: 1;
  overflow-y: auto; // Allow scrolling if content exceeds height

  @media (max-width: 767px) {
    border-radius: 0 0 10px 10px; // Adjust border radius for mobile
  }
`;

const AnimatedSection = styled(SectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const Title = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.primary}; 
`;

const MindMapWrapper = styled.div`
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  margin-top: -2rem; // Move the wrapper up

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin-top: -1rem; // Adjust for mobile if needed
  }
`;

const tabContents = {
  tab1: (
    <>
      <Paragraph>Easily upload and organize your documents for processing.</Paragraph>
      <Paragraph>Our intuitive interface allows you to drag and drop files or select them from your device.</Paragraph>
      <Paragraph>Supported formats include PDF, DOC, DOCX, and plain text files.</Paragraph>
    </>
  ),
  tab2: (
    <>
      <Paragraph>Identify and extract key entities from your documents for AI retreival.</Paragraph>
      <Paragraph>Our advanced NLP algorithms recognize people, organizations, locations, and custom entities.</Paragraph>
      <Paragraph>Easily review and edit extracted entities to ensure accuracy.</Paragraph>
    </>
  ),
  tab3: (
    <>
      <Paragraph>Visualize relationships between entities in an interactive graph.</Paragraph>
      <Paragraph>Explore connections and hierarchies with our dynamic, zoomable graph interface.</Paragraph>
      <Paragraph>Customize graph layouts and filter entities to focus on specific areas of interest.</Paragraph>
    </>
  ),
  tab4: (
    <>
      <Paragraph>Get in-depth analysis and insights from your processed documents.</Paragraph>
      <Paragraph>Generate comprehensive reports highlighting key findings and trends.</Paragraph>
      <Paragraph>Utilize AI-powered suggestions to uncover hidden patterns and relationships in your data.</Paragraph>
    </>
  ),
};

const tabs = [
  { id: 'tab1', title: 'Upload', content: tabContents.tab1 },
  { id: 'tab2', title: 'Entities', content: tabContents.tab2 },
  { id: 'tab3', title: 'Graph', content: tabContents.tab3 },
  { id: 'tab4', title: 'Analysis', content: tabContents.tab4 },
];

const UserExperienceSection = ({ animationDelay = 0.2 }) => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [isVisible, setIsVisible] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setAnimationKey(prevKey => prevKey + 1);
  };

  return (
    <AnimatedSection id="user-experience" ref={sectionRef} className={isVisible ? 'visible' : ''} delay={animationDelay}>
      <TransparentContentWrapper>
        <FlexWrapper>
          <ContentWrapper>
            <Title>User Experience</Title>
            <TabContainer>
              {tabs.map(tab => (
                <Tab
                  key={tab.id}
                  active={activeTab === tab.id}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.title}
                </Tab>
              ))}
            </TabContainer>
            <TabContentContainer>
              {tabs.map(tab => (
                <TabContent key={tab.id} active={activeTab === tab.id}>
                  {tab.content}
                </TabContent>
              ))}
            </TabContentContainer>
          </ContentWrapper>
          <MindMapWrapper>
            <AnimateMindMap key={animationKey} width={300} height={300} />
          </MindMapWrapper>
        </FlexWrapper>
      </TransparentContentWrapper>
    </AnimatedSection>
  );
};

export default UserExperienceSection;