import styled, { css } from 'styled-components';

export const SectionWrapper = styled.section`
  padding: 4rem 2rem;
  width: 100%;
  box-sizing: border-box;
`;

export const TransparentContentWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 10px;
  padding: 2rem;
  background-color: transparent;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  transition: box-shadow 0.3s ease-in-out;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.borderColor};
  }

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme === 'light' ? 'transparent' : 'rgba(18, 18, 18, 0.7)'};
  color: ${({ theme }) => theme === 'light' ? '#000000' : '#ffffff'};
  border: 2px solid ${({ theme }) => theme.colors.accent};
  border-radius: 15px;
  padding: 2rem;
  margin: 0 2rem;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.accent};
  }
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.accent};
  // ... other styles
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 400;
  // ... other styles
`;

export const AnimatedSectionWrapper = styled.div`
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.5s ease-out ${({ delay }) => delay}s, 
              transform 0.5s ease-out ${({ delay }) => delay}s;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: scale(1);
    `}
`;