import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import Header from './components/Header';
import HomeSection from './components/HomeSection';
import TrackingDocs from './components/TrackingDocs';
import IndustriesSection from './components/IndustriesSection';
import SecuritySection from './components/SecuritySection';
import UserExperienceSection from './components/UserExperienceSection';
import TechnologySection from './components/TechSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import { GlobalStyle } from './styles/GlobalStyle';

const MainContent = styled.main`
  padding-top: 2rem; // Adjust this value as needed
`;

const ThemedApp = () => {
  const { theme } = useTheme();

  return (
    <Router>
      <GlobalStyle theme={theme} />
      <Header />
      <MainContent>
        <HomeSection />
        <TrackingDocs />
        <IndustriesSection />
        <SecuritySection />
        <UserExperienceSection />
        <TechnologySection />
        <ContactSection />
      </MainContent>
      <Footer />
    </Router>
  );
};

const App = () => {
  return (
    <ThemeProvider>
      <ThemedApp />
    </ThemeProvider>
  );
};

export default App;