/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper as BaseTransparentContentWrapper, Title as BaseTitle, Paragraph } from './CommonStyles';
import AnimatedBoxes from './AnimatedBoxes';

const AnimatedSection = styled(BaseSectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;
  width: 100%;
  max-width: none;
  padding: 4rem 0;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const AnimatedBoxesContainer = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 2px; // Added inner padding

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const TransparentContentWrapper = styled(BaseTransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  flex: 1;
  max-width: 60%; // Narrower width for larger screens
  
  @media (max-width: 1024px) {
    max-width: 70%; // Slightly wider on medium screens
  }

  @media (max-width: 768px) {
    max-width: 100%; // Full width on mobile
  }
`;

const Title = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.primary};
`;

const TrackingDocs = ({ animationDelay = 0.2 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleAnimationClick = () => {
    setAnimationKey(prevKey => prevKey + 1);
  };

  const GreenText = styled.span`
  color: #00ff00; // Lime green color
`;

  return (
    <AnimatedSection 
      id="tracking" 
      ref={sectionRef} 
      className={isVisible ? 'visible' : ''} 
      delay={animationDelay}
    >
      <TransparentContentWrapper>
        <FlexWrapper>
          <AnimatedBoxesContainer onClick={handleAnimationClick}>
            <AnimatedBoxes key={animationKey} />
          </AnimatedBoxesContainer>
          <TextContent>
            <Title>Tracking Document Dependencies</Title>
            <Paragraph>
            Doc Miner transforms how organizations manage and monetize their data.  AI analysis of content and all supporting reference.  Our cutting-edge technology transforms raw documents into structured, actionable data.
            </Paragraph>
            <Paragraph>
              From OCR to natural language processing, Doc-Miner employs advanced algorithms to extract key information, identify patterns, and provide valuable insights.
            </Paragraph>
          </TextContent>
        </FlexWrapper>
      </TransparentContentWrapper>
    </AnimatedSection>
  );
};

export default TrackingDocs;