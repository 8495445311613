import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper as BaseTransparentContentWrapper, Title as BaseTitle, Paragraph } from './CommonStyles';
import SecurityScrambler from './SecurityScrambler';

const AnimatedSection = styled(BaseSectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;
  width: 100%;
  max-width: none;
  padding: 4rem 0;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const TransparentContentWrapper = styled(BaseTransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  flex: 1;
  max-width: 60%; // Narrower width for larger screens
  
  @media (max-width: 1024px) {
    max-width: 70%; // Slightly wider on medium screens
  }

  @media (max-width: 768px) {
    max-width: 100%; // Full width on mobile
  }
`;

const ScrambleWrapper = styled.div`
  width: 300px;
  height: 300px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const InteractiveScrambleWrapper = styled(ScrambleWrapper)`
  cursor: default;
`;

const Title = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.primary};
`;

const SecuritySection = ({ animationDelay = 0.3 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [startScrambler, setStartScrambler] = useState(false);
  const sectionRef = useRef(null);
  const [resetScrambler, setResetScrambler] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
          // Start the scrambler after 2 seconds
          setTimeout(() => setStartScrambler(true), 1200);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleScramblerReset = useCallback(() => {
    setResetScrambler(prev => prev + 1);
  }, []);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleScramblerReset();
    }
  }, [handleScramblerReset]);

  return (
    <AnimatedSection 
      id="security" 
      ref={sectionRef} 
      className={isVisible ? 'visible' : ''} 
      delay={animationDelay}
    >
      <TransparentContentWrapper>
        <FlexWrapper>
          <InteractiveScrambleWrapper 
            onClick={handleScramblerReset}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role="button"
            aria-label="Reset security scrambler"
          >
            {startScrambler && <SecurityScrambler key={resetScrambler} />}
          </InteractiveScrambleWrapper>
          <TextContent>
            <Title>Secure Your Data</Title>
            <Paragraph>
              At Doc Miner, we prioritize the security and confidentiality of your documents. Our state-of-the-art encryption and access control measures ensure that your sensitive information remains protected at all times.
            </Paragraph>
            <Paragraph>
              With features like end-to-end encryption, multi-factor authentication, and detailed audit logs, you can trust Doc Miner to keep your data safe while providing the insights you need.
            </Paragraph>
          </TextContent>
        </FlexWrapper>
      </TransparentContentWrapper>
    </AnimatedSection>
  );
};

export default SecuritySection;