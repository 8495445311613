import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  perspective: 1000px;
`;

const IsometricGrid = styled.div`
  width: 100%;
  height: 100%;
  transform: rotateX(60deg) rotateZ(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Processor = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: ${props => props.color}80;
  transform: ${props => `translate(${props.x}px, ${props.y}px) translateZ(${props.height}px)`};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.color};
    transform: translateZ(-15px);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 15px;
    background-color: ${props => props.color}CC;
    transform: rotateX(-90deg) translateY(-15px);
    transform-origin: top;
  }
`;

const Circuit = styled.div`
  position: absolute;
  background-color: #888;
  transform: ${props => `translate(${props.x}px, ${props.y}px) rotate(${props.rotate}deg)`};
  width: ${props => props.length}px;
  height: 4px;
`;

const moveSignal = keyframes`
  0% { offset-distance: 0%; }
  100% { offset-distance: 100%; }
`;

const Signal = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  offset-path: path("M-55,-56 L66,-56 L66,65 L-55,65 Z");
  animation: ${moveSignal} 16s linear infinite;
`;

const moveSignalClockwise = keyframes`
  0% { offset-distance: 0%; }
  100% { offset-distance: 100%; }
`;

const moveSignalCounterClockwise = keyframes`
  0% { offset-distance: 100%; }
  100% { offset-distance: 0%; }
`;

const SignalClockwise = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  offset-path: path("M-55,-56 L66,-56 L66,65 L-55,65 Z");
  animation: ${moveSignalClockwise} 16s linear infinite;
`;

const SignalCounterClockwise = styled(SignalClockwise)`
  background-color: #ff00ff;
  animation: ${moveSignalCounterClockwise} 16s linear infinite;
`;

const moveCrossDot = keyframes`
  0%, 100% { offset-distance: 0%; }
  50% { offset-distance: 100%; }
`;

const CrossDotVertical = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffff00; // Yellow color
  border-radius: 50%;
  offset-path: path("M7,-90 L7,90");
  animation: ${moveCrossDot} 18s linear infinite;
`;

const CrossDotHorizontal = styled(CrossDotVertical)`
  background-color: #00bfff; // Light blue color (DeepSkyBlue)
  offset-path: path("M-86,6 L94,6");
`;

const AnimatedTech = ({ seed = 0 }) => {
  // Function to adjust color hue
  const adjustHue = (color, amount) => {
    const hue = (parseInt(color.slice(1), 16) + amount) % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  // Use seed to create a hue adjustment amount
  const hueAdjustment = seed * 20; // This multiplier can be adjusted

  // Array of base colors for nodes
  const baseColors = [
    '#4A90E2', '#50E3C2', '#F5A623', '#D0021B', '#9013FE', 
    '#417505', '#7ED321', '#BD10E0', '#FF6B6B', '#4ECDC4', 
    '#45B7D1', '#96C93D'
  ];

  // Function to get a unique color for each index
  const getUniqueColor = (index) => {
    return adjustHue(baseColors[index % baseColors.length], hueAdjustment + index * 30);
  };

  return (
    <Container>
      <IsometricGrid>
        {/* Inner processors */}
        <Processor color={getUniqueColor(0)} x={-30} y={-30} height={30} />
        <Processor color={getUniqueColor(1)} x={30} y={-30} height={30} />
        <Processor color={getUniqueColor(2)} x={-30} y={30} height={30} />
        <Processor color={getUniqueColor(3)} x={30} y={30} height={30} />

        {/* Outer processors */}
        <Processor color={getUniqueColor(4)} x={-90} y={-90} height={40} />
        <Processor color={getUniqueColor(5)} x={90} y={-90} height={40} />
        <Processor color={getUniqueColor(6)} x={-90} y={90} height={40} />
        <Processor color={getUniqueColor(7)} x={90} y={90} height={40} />
        <Processor color={getUniqueColor(8)} x={-120} y={0} height={40} />
        <Processor color={getUniqueColor(9)} x={120} y={0} height={40} />
        <Processor color={getUniqueColor(10)} x={0} y={-120} height={40} />
        <Processor color={getUniqueColor(11)} x={0} y={120} height={40} />

        {/* Render all circuits */}
        <Circuit x={-30} y={0} length={60} rotate={0} />
        <Circuit x={0} y={-30} length={60} rotate={90} />
        <Circuit x={30} y={0} length={60} rotate={0} />
        <Circuit x={0} y={30} length={60} rotate={90} />
        <Circuit x={-60} y={-30} length={60} rotate={90} />
        <Circuit x={60} y={-30} length={60} rotate={90} />
        <Circuit x={-60} y={30} length={60} rotate={90} />
        <Circuit x={60} y={30} length={60} rotate={90} />
        <Circuit x={-30} y={-60} length={60} rotate={0} />
        <Circuit x={30} y={-60} length={60} rotate={0} />
        <Circuit x={-30} y={60} length={60} rotate={0} />
        <Circuit x={30} y={60} length={60} rotate={0} />
        <Circuit x={-75} y={0} length={30} rotate={0} />
        <Circuit x={-90} y={0} length={50} rotate={90} />
        <Circuit x={-115} y={-25} length={50} rotate={0} />
        <Circuit x={-115} y={25} length={50} rotate={0} />
        <Circuit x={75} y={0} length={30} rotate={0} />
        <Circuit x={90} y={0} length={50} rotate={90} />
        <Circuit x={115} y={-25} length={50} rotate={0} />
        <Circuit x={115} y={25} length={50} rotate={0} />
        <Circuit x={0} y={-75} length={30} rotate={90} />
        <Circuit x={0} y={-90} length={50} rotate={0} />
        <Circuit x={-25} y={-115} length={50} rotate={90} />
        <Circuit x={25} y={-115} length={50} rotate={90} />
        <Circuit x={0} y={75} length={30} rotate={90} />
        <Circuit x={0} y={90} length={50} rotate={0} />
        <Circuit x={-25} y={115} length={50} rotate={90} />
        <Circuit x={25} y={115} length={50} rotate={90} />

        {/* Four signals moving clockwise around the square path */}
        <SignalClockwise style={{ animationDelay: '0s', backgroundColor: '#00ff00' }} />
        <SignalClockwise style={{ animationDelay: '-4s', backgroundColor: '#00ff00' }} />
        <SignalClockwise style={{ animationDelay: '-8s', backgroundColor: '#00ff00' }} />
        <SignalClockwise style={{ animationDelay: '-12s', backgroundColor: '#00ff00' }} />

        {/* Two signals moving counterclockwise */}
        <SignalCounterClockwise style={{ animationDelay: '0s', backgroundColor: '#ff00ff' }} />
        <SignalCounterClockwise style={{ animationDelay: '-8s', backgroundColor: '#ff00ff' }} />

        {/* Yellow dot moving along the vertical axis */}
        <CrossDotVertical style={{ animationDelay: '0s', backgroundColor: '#ffff00' }} />

        {/* Blue dot moving along the horizontal axis */}
        <CrossDotHorizontal style={{ animationDelay: '-9s', backgroundColor: '#00bfff' }} />
      </IsometricGrid>
    </Container>
  );
};

export default AnimatedTech;