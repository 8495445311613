import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const SVGContainer = styled.svg`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

const AnimatedGroup = styled.g`
  transition: all 0.5s ease-in-out;
`;

const growHorizontal = keyframes`
  from {
    transform: scaleX(0);
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    opacity: 1;
  }
`;

const growVertical = keyframes`
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
`;

const AnimatedRect = styled.rect`
  animation: ${props => props.dy === 0 ? growHorizontal : growVertical} 0.125s ease-out;
  transform-origin: ${props => {
    if (props.dx === 1) return '0 50%';
    if (props.dx === -1) return '100% 50%';
    if (props.dy === 1) return '50% 0';
    if (props.dy === -1) return '50% 100%';
    return 'center';
  }};
`;

const colors = ['#5A189A', '#9D4EDD', '#C77DFF', '#3D405B', '#81B29A'];
const edgeColor = '#87CEFA'; // Light blue color for edges
const boxBorderColor = '#006400'; // Dark green color for box borders

const boxSize = 21; // Reduced from 30
const padding = 6; // Reduced from 10
const gridSize = 5; // Keeping this the same
const maxBoxes = Math.floor(gridSize * gridSize * 0.5); // 60% of the grid

// Add this constant for extra space around the grid
const extraSpace = boxSize / 2;

const createEdge = (box1, box2) => {
  const dx = box2.x - box1.x;
  const dy = box2.y - box1.y;
  let start, end;

  if (dx > 0) {
    start = { x: box1.x + boxSize, y: box1.y + boxSize / 2 };
    end = { x: box2.x, y: box2.y + boxSize / 2 };
  } else if (dx < 0) {
    start = { x: box1.x, y: box1.y + boxSize / 2 };
    end = { x: box2.x + boxSize, y: box2.y + boxSize / 2 };
  } else if (dy > 0) {
    start = { x: box1.x + boxSize / 2, y: box1.y + boxSize };
    end = { x: box2.x + boxSize / 2, y: box2.y };
  } else {
    start = { x: box1.x + boxSize / 2, y: box1.y };
    end = { x: box2.x + boxSize / 2, y: box2.y + boxSize };
  }

  return `M${start.x},${start.y} L${end.x},${end.y}`;
};

const AnimatedBoxes = () => {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const createInitialBox = () => ({
      x: Math.floor(gridSize / 2) * (boxSize + padding) + extraSpace,
      y: Math.floor(gridSize / 2) * (boxSize + padding) + extraSpace,
      color: colors[Math.floor(Math.random() * colors.length)],
    });

    const isValidPosition = (x, y, existingBoxes) => {
      return x >= extraSpace && x < (gridSize * (boxSize + padding) + extraSpace) &&
             y >= extraSpace && y < (gridSize * (boxSize + padding) + extraSpace) &&
             !existingBoxes.some(box => box.x === x && box.y === y);
    };

    const countNeighbors = (x, y, existingBoxes) => {
      const directions = [
        { dx: 1, dy: 0 },
        { dx: -1, dy: 0 },
        { dx: 0, dy: 1 },
        { dx: 0, dy: -1 },
      ];

      return directions.filter(({ dx, dy }) => 
        existingBoxes.some(box => box.x === x + dx * (boxSize + padding) && box.y === y + dy * (boxSize + padding))
      ).length;
    };

    const addBox = (prevBoxes) => {
      if (prevBoxes.length === 0) return [createInitialBox()];
      if (prevBoxes.length >= maxBoxes) return prevBoxes;

      const directions = [
        { dx: 1, dy: 0 },
        { dx: -1, dy: 0 },
        { dx: 0, dy: 1 },
        { dx: 0, dy: -1 },
      ];

      const availablePositions = prevBoxes.flatMap(box => 
        directions.map(({ dx, dy }) => ({
          x: box.x + dx * (boxSize + padding),
          y: box.y + dy * (boxSize + padding),
          parent: box,
          dx,
          dy
        }))
      ).filter(pos => 
        isValidPosition(pos.x, pos.y, prevBoxes) && 
        countNeighbors(pos.x, pos.y, prevBoxes) < 2
      );

      if (availablePositions.length === 0) return prevBoxes;

      const newPos = availablePositions[Math.floor(Math.random() * availablePositions.length)];
      const newBox = {
        x: newPos.x,
        y: newPos.y,
        color: colors[Math.floor(Math.random() * colors.length)],
        parent: newPos.parent,
        dx: newPos.dx,
        dy: newPos.dy
      };

      return [...prevBoxes, newBox];
    };

    const growNetwork = () => {
      setBoxes(prevBoxes => addBox(prevBoxes));
    };

    // Start with an initial box
    setBoxes([createInitialBox()]);

    // Set up the interval to add boxes
    const interval = setInterval(growNetwork, 250);

    // Cleanup function
    return () => clearInterval(interval);
  }, []);

  const renderBoxWithChildren = (box, allBoxes) => {
    const children = allBoxes.filter(b => b.parent === box);
    return (
      <React.Fragment key={`${box.x},${box.y}`}>
        {children.map(child => renderBoxWithChildren(child, allBoxes))}
        {box.parent && (
          <path
            d={createEdge(box.parent, box)}
            stroke={edgeColor}
            fill="none"
            strokeWidth="1"
          />
        )}
        <AnimatedGroup transform={`translate(${box.x}, ${box.y})`}>
          <AnimatedRect
            x={0}
            y={0}
            width={boxSize}
            height={boxSize}
            rx={4}
            ry={4}
            fill={box.color}
            stroke={boxBorderColor}
            strokeWidth="1"
            dx={box.dx}
            dy={box.dy}
          />
        </AnimatedGroup>
      </React.Fragment>
    );
  };

  return (
    <SVGContainer 
      viewBox={`0 0 ${(boxSize + padding) * gridSize + extraSpace * 2} ${(boxSize + padding) * gridSize + extraSpace * 2}`} 
      preserveAspectRatio="xMidYMid meet"
    >
      <AnimatedGroup>
        {boxes.filter(box => !box.parent).map(rootBox => renderBoxWithChildren(rootBox, boxes))}
      </AnimatedGroup>
    </SVGContainer>
  );
};

export default AnimatedBoxes;