import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import ReactGlobe from 'react-globe.gl';
import * as THREE from 'three';  // Add this import

const colorSchemes = {
  0: 'invisible',  // Special case for invisible wireframe
  1: 0xaaaaaa,     // Default light gray color
  2: 0x00ff00,     // Bright Green
  3: 0xff00ff,     // Bright Magenta
  4: 0xffff00,     // Bright Yellow
  5: 0x00ffff,     // Bright Cyan
  6: 0xff8000,     // Bright Orange
  7: 0x8000ff      // Bright Purple
};

const EarthGlobe = ({ width, height, colorSchemeIndex = 0 }) => {
  const globeRef = useRef();

  const customLayerData = useMemo(() => [{}], []);

  const customLayerRenderer = useCallback((globe) => {
    const estimatedGlobeRadius = 100;
    const latitudeCount = 18;  // Reduced for clarity
    const longitudeCount = 36; // Reduced for clarity

    const group = new THREE.Group();

    // Handle invisible case
    if (colorSchemeIndex === 0) {
      return group; // Return empty group for invisible wireframe
    }

    const wireframeColor = colorSchemes[colorSchemeIndex] || colorSchemes[1];

    // Create latitude lines
    for (let lat = 0; lat <= latitudeCount; lat++) {
      const phi = (lat / latitudeCount) * Math.PI;
      const points = [];
      for (let long = 0; long <= 100; long++) {
        const theta = (long / 100) * 2 * Math.PI;
        const x = estimatedGlobeRadius * Math.sin(phi) * Math.cos(theta);
        const y = estimatedGlobeRadius * Math.cos(phi);
        const z = estimatedGlobeRadius * Math.sin(phi) * Math.sin(theta);
        points.push(new THREE.Vector3(x, y, z));
      }
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ color: wireframeColor, transparent: true, opacity: 0.5 });
      const line = new THREE.Line(geometry, material);
      group.add(line);
    }

    // Create longitude lines
    for (let long = 0; long < longitudeCount; long++) {
      const theta = (long / longitudeCount) * 2 * Math.PI;
      const points = [];
      for (let lat = 0; lat <= 100; lat++) {
        const phi = (lat / 100) * Math.PI;
        const x = estimatedGlobeRadius * Math.sin(phi) * Math.cos(theta);
        const y = estimatedGlobeRadius * Math.cos(phi);
        const z = estimatedGlobeRadius * Math.sin(phi) * Math.sin(theta);
        points.push(new THREE.Vector3(x, y, z));
      }
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ color: wireframeColor, transparent: true, opacity: 0.5 });
      const line = new THREE.Line(geometry, material);
      group.add(line);
    }

    return group;
  }, [colorSchemeIndex]);

  useEffect(() => {
    if (globeRef.current) {
      const globe = globeRef.current;
      
      console.log("Globe ref acquired");

      // Existing code for controls
      const controls = globe.controls();
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.15; // Reduced from 0.33 to 0.05 for slower rotation
      controls.enableZoom = false;
      controls.mouseButtons = { 
        LEFT: THREE.MOUSE.ROTATE, 
        MIDDLE: THREE.MOUSE.PAN, 
        RIGHT: THREE.MOUSE.PAN 
      };

      function animate() {
        controls.update();
        requestAnimationFrame(animate);
      }

      animate();
    }
  }, []);

  return (
    <ReactGlobe
      ref={globeRef}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
      bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
      width={width}
      height={height}
      backgroundColor="rgba(0,0,0,0)"
      atmosphereColor="lightskyblue"
      atmosphereAltitude={0.15}
      animateIn={false}
      enableZoomPan={false}
      globeScale={1.18}  // Add this line to increase the globe size by 18%
      customLayerData={customLayerData}
      customThreeObject={customLayerRenderer}
    />
  );
};

const Globe = ({ width, height, colorSchemeIndex }) => {
  return <EarthGlobe width={width} height={height} colorSchemeIndex={colorSchemeIndex} />;
};

export default Globe;