import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { SectionWrapper as BaseSectionWrapper, TransparentContentWrapper as BaseTransparentContentWrapper, Title as BaseTitle, Paragraph } from './CommonStyles';
import AnimatedSection from './AnimatedSection';
import AnimatedTech from './AnimatedTech';

const AnimatedSectionStyled = styled(BaseSectionWrapper)`
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition-delay: ${props => props.delay}s;
  width: 100%;
  max-width: none;
  padding: 4rem 0;

  &.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const TransparentContentWrapper = styled(BaseTransparentContentWrapper)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1240px) {
    max-width: calc(100% - 4rem);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: calc(100% - 2rem);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  flex: 1;
  max-width: 60%; // Narrower width for larger screens
  
  @media (max-width: 1024px) {
    max-width: 70%; // Slightly wider on medium screens
  }

  @media (max-width: 768px) {
    max-width: 100%; // Full width on mobile
  }
`;

const AnimationWrapper = styled.div`
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const Title = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.primary};
`;

const TechSection = ({ animationDelay = 0.4 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationSeed, setAnimationSeed] = useState(0);
  const sectionRef = useRef(null);

  const handleAnimationClick = useCallback(() => {
    setAnimationSeed(prev => prev + 1);
  }, []);

  const handleAnimationKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAnimationClick();
    }
  }, [handleAnimationClick]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current && observer) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <AnimatedSectionStyled 
      id="technology" 
      ref={sectionRef} 
      className={isVisible ? 'visible' : ''} 
      delay={animationDelay}
    >
      <TransparentContentWrapper>
        <FlexWrapper>
          <AnimationWrapper 
            onClick={handleAnimationClick}
            onKeyDown={handleAnimationKeyDown}
            tabIndex={0}
            role="button"
            aria-label="Refresh technology animation"
          >
            <AnimatedTech seed={animationSeed} isVisible={isVisible} />
          </AnimationWrapper>
          <TextContent>
            <Title>Leading Technologies</Title>
            <Paragraph>
              Doc-Miner leverages the latest advancements in artificial intelligence and machine learning to provide unparalleled document analysis capabilities.
            </Paragraph>
            <Paragraph>
              Our technology stack includes natural language processing, computer vision, and deep learning algorithms, enabling accurate extraction and interpretation of complex document data.
            </Paragraph>
          </TextContent>
        </FlexWrapper>
      </TransparentContentWrapper>
    </AnimatedSectionStyled>
  );
};

export default TechSection;